.dashBoardMainSearch {
    position: relative;
    height: 110px;
}

.searchImg {
    position: absolute;
    padding: 10px;

}

.mainInput {
    padding: 15px;
    padding-left: 55px;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 8px;
}

.dashBoardMainSelect {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

}

.dashBoardMainSelectbutton {
    margin-right: 8px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 30px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    background-color: #f8f8f8;
    border: 1px solid var(--borderColor);
}

.dashBoardMainSelectbuttonActive {
    margin-right: 8px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 35px;
    border-radius: 8px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    background-color: #f8f8f8;
    border: 1px solid var(--borderColor);
}



.dashBoardMainSelect img {
    position: absolute;
    right: 8px;
}


.hourlyRate {
    width: 450px;
    position: relative;
    z-index: 5 !important;
    padding: 25px;
    padding-bottom: 20px;
    margin-top: 15px;
    border-radius: 10px;
    background-color: var(--white);
    border: 1px solid var(--borderColor);
}

.hourlyRate h1 {
    font-size: 16px;
    font-weight: 600;
    /* margin-bottom: 35px; */
}

.hourlyRate h3 {
    font-size: 14px;
    opacity: 0.7;

}

.hourlyButtonReset {
    width: 48%;
    margin-Right: 4%;
    font-weight: 600;
    opacity: 0.7;
    padding: 10px;
    border: 1px solid var(--borderColor);
    border-radius: 8px;
}

.hourlyButtonFilter {
    width: 48%;
    font-weight: 600;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid var(--borderColor);
    background-color: var(--primaryColor);
    color: var(--white);
}

.location {
    width: 300px;
    height: 280px;
    position: relative;
    left: 12.5%;
    z-index: 5;
    padding: 5px;
    margin-top: 15px;
    border-radius: 10px;
    background-color: var(--white);
    border: 1px solid var(--borderColor);
}

.locInput {
    width: 100%;

    padding: 10px;
    border-bottom: 1px solid var(--borderColor);
}

.checkInput {
    margin-right: 15px;
    background-color: var(--primaryColor);

}

.checkInput:checked {
    background-color: var(--primaryColor);
    color: var(--primaryColor);

}

.locationDesc {
    width: 100%;
    padding: 15px;
    height: 210px;
    overflow-y: scroll;
}

.locationDesc::-webkit-scrollbar {
    display: none;
}

.locDesc {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.locDesc p {
    font-size: 16px;
    font-weight: 500;
}

.dashBoardMainSelectbutton h6 {
    height: 18px;
    width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 500;
    /* padding: 1px 5px; */
    background-color: #e4deff;
    border-radius: 50%;
    margin-left: 6px;
}

/* all Filter */

.allFilter {
    width: 70%;
    margin-left: auto;
    margin-left: auto;
    /* padding-bottom: 10px; */
    background-color: var(--white);
    position: fixed;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translate(-44%, -50%);
    border-radius: 8px;
    border: 1px solid var(--borderColor);
}

.allFilterHead {
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: var(--dropColor);
    border: 1px solid var(--dropBorderColor);
    padding: 15px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.allFilterHeadLeft {
    display: flex;
    align-items: center;
    justify-content: center;
}

.allFilterHeadLeft img {
    margin-right: 15px;
}

.FilterToggleLeft {
    display: flex;
}

.workHistory {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 15px;
}

.FilterToggle {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.FilterToggle h2 {
    font-size: 14px;
    font-weight: 600;
    margin: 0px 10px;
}

.filterSkill {
    width: 100%;
    padding: 30px;
    padding-top: 10px;

}

.filterSkill h2 {
    font-size: 14px;
    font-weight: 600;
}

.filterSkill input {
    border: 1px solid var(--borderColor);
    padding: 10px;
    /* height: 70px; */
    width: 100%;
    margin-top: 10px;
    border-radius: 8px;
}

.css-13cymwt-control {
    height: 80px;
}

.filterSkillHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.filterSkillHead h3 {
    color: var(--textColor);
    font-size: 14px;
}

.allFilterBody {
    width: 100%;
    padding: 0px 30px;
    display: flex;
    align-items: self-start;
    justify-content: center;
    text-align: center;
}

.allFilterBody h4 {
    font-size: 12px;
    font-weight: 500;
    margin-top: 5px;
    /* margin-bottom: 5px; */
}

.allFilterBody h2 {
    font-size: 14px;
    font-weight: 600;
}

.allFilterBody h3 {
    font-size: 15px;
    color: var(--textColor);
}

.filterRate {
    width: 50%;
    margin-right: 40px;
}

.fliterLocation {
    width: 50%;
    text-align: left;
}

.fliterLocation input {
    width: 100%;
    padding: 15px 10px;
    border-radius: 8px;
    margin-top: 15px;
    border: 1px solid var(--borderColor);
}

.filterRateHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.allFilterBodyBottom {
    width: 100%;
    padding: 15px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.allFilterBodyBottom h2 {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 10px;
}

.allFilterBodyBottom input {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--borderColor);
    border-radius: 8px;
}

.workExp {
    width: 50%;
    margin-right: 40px;
}

.language {
    width: 50%;
}

.allFilterBodyButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 30px 30px;
}

.ResetAll {

    margin-Right: 4%;
    font-weight: 600;
    opacity: 0.7;
    padding: 10px 50px;
    border: 1px solid var(--borderColor);
    border-radius: 8px;
}

.SetFilter {

    font-weight: 600;
    padding: 10px 40px;
    border-radius: 8px;
    border: 1px solid var(--borderColor);
    background-color: var(--primaryColor);
    color: var(--white);
}

.searchButton {
    padding: 5px;
    border: 1px solid var(--borderColor);
    border-radius: 8px;
    font-size: 12px;
    color: var(--textColor);
    position: absolute;
    background-color: var(--disableColor);
    opacity: 0.5;
    right: 10%;
    top: 10%;
}

.searchButtonActive {
    padding: 5px;
    border: 1px solid var(--primaryColor);
    border-radius: 8px;
    font-size: 12px;
    color: var(--white);
    position: absolute;
    background-color: var(--primaryColor);
    right: 10%;
    top: 10%;
    z-index: 2;
}

/* drop down */

.searchDropDown {
    /* height: 100%; */
    width: 91%;
    background-color: var(--white);
    position: absolute;
    top: 50%;
    border: 1px solid var(--borderColor);
    border-top: none;
    /* z-index: 8; */
    border-radius: 8px;
}

.searchDropDown h2 {
    font-size: 16px;
    font-weight: 500;

    padding: 10px 25px;

}

.searchDropDown h2:hover {
    background-color: var(--disableColor);
    border-radius: 8px;
}

@media only screen and (max-width: 600px) {
    .dashBoardMainSearch {
        padding-right: 0px;
    }

    .allFilter {
        width: 100%;
        transform: translate(-50%, -50%);
        overflow-y: scroll;
        height: 100%;
    }

    .FilterToggle {
        width: 100%;
        flex-direction: column;
        padding: 0px 10px;
    }

    .FilterToggleLeft {
        width: 100%;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
    }

    .workHistory {
        justify-content: space-between;
    }

    .allFilterBodyButton {
        flex-direction: column;
        padding: 20px;
        padding-top: 10px;

    }

    .ResetAll {
        margin-right: 0px;
        width: 100%;
        padding: 8px 10px;
        margin-bottom: 15px;
    }

    .SetFilter {
        width: 100%;
        padding: 8px 10px;
    }

    .filterRate {
        width: 100%;
        margin-right: 0px;
    }

    .filterSkill {
        padding: 0px 20px;
        padding-top: 15px;
    }

    .filterSkillHead {
        margin-bottom: 10px;
    }

    .allFilterBody {
        flex-direction: column;
        padding: 20px;

    }

    .allFilterBody h4 {
        margin-top: 0px;
    }

    .allFilterBodyBottom {
        flex-direction: column;
        padding: 0px 20px;

    }

    .allFilterBodyBottom input {
        margin-bottom: 15px;
    }

    .workExp {
        margin-right: 0px;
        width: 100%;
    }

    .language {
        width: 100%;
    }

    .fliterLocation {
        width: 100%;
    }

    .fliterLocation h2 {
        margin-bottom: 10px;
    }

    .location {
        width: 310px;
        left: 0;
    }

    .searchButton {
        opacity: 1;
        right: 5%;
        top: 12%;
    }

    .dashBoardMainSelect {
        justify-content: space-between;
    }

    .dashBoardMainSelectbutton {
        margin-right: 0px;
        font-size: 9px;
        padding-left: 5px;
    }

    .recentHeadRight h2 {
        font-size: 10px;
    }

    .sideNav {
        height: 100vh;
    }
}