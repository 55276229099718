.HireMainComponentFlex {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 50px;
    padding-top: 30px;
    overflow: hidden;
}

.HireMainComponent {
    padding-top: 25px;
    padding-bottom: 50px;
}

.HireMainComponent1 {
    padding-top: 25px;
}

.completebtn {
    background-color: green;
    color: white;
    border-radius: 5px;
    padding: 10px 20px;
    font-weight: 500;
    width: 100%;
}

.cancelbtn {
    background-color: red;
    color: white;
    border-radius: 5px;
    padding: 10px 20px;
    font-weight: 500;
    width: 100%;
}

.loadingIconHAT {
    color: var(--primaryColor);
    font-size: 30px;
    text-align: center;
    transform: rotate(180deg);
    animation: rotate 0.3s infinite;

}

.statuscontracted {
    color: green;
    background-color: #DCFCE7;
    font-size: 11px;
    text-align: center;
    font-weight: 500;
    padding: 5px 30px;
    border-radius: 20px;
}

.statuswaiting-approval {
    background-color: #ffd700;
    font-size: 11px;
    text-align: center;
    font-weight: 500;
    padding: 5px 30px;
    border-radius: 20px;
    color: black;
}

.statusprocesing {
    color: #3B82F6;
    background-color: #DBEAFE;
    font-size: 11px;
    text-align: center;
    font-weight: 500;
    padding: 5px 30px;
    border-radius: 20px;
}

.statusinterview {
    color: #6B7280;
    background-color: #80808033;
    font-size: 11px;
    text-align: center;
    font-weight: 500;
    padding: 5px 30px;
    border-radius: 20px;
}

.HireMainComponentLeft {
    width: 100%;
    border: 1px solid var(--borderColor);
    border-radius: 8px;
    padding: 20px;
    background-color: var(--white);
}

.HireMainComponentLeft h2 {
    font-size: 16px;
    color: var(--textColor);
    margin-bottom: 20px;
    font-weight: 500;
}

.HireMainComponentLeftContent {
    width: 100%;
    margin-bottom: 15px;
}

.HireMainComponentLeftContent h3 {
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 600;
}

.HireMainComponentLeftContentInput {
    width: 100%;
    border: 1px solid var(--borderColor);
    border-radius: 8px;
    padding: 8px;

}

.HireMainComponentLeftContentInput1 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.width50 {
    width: 50% !important;
}

.skilllistdiv {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 1rem;
}

.HireMainComponentLeftContent select {
    width: 40%;
    border: 1px solid var(--borderColor);
    border-right: none;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    padding: 8px;
}

.HireMainComponentRight {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 50px;
}

.HireMainComponentRightButton {
    font-size: 20px;
    width: 100%;
    padding: 14px;
    border-radius: 8px;
    background-color: var(--buttonDisableColor);
}

.HireMainComponentRightButtonActive {
    color: var(--white);
    font-size: 20px;
    width: 100%;
    padding: 14px;
    border-radius: 8px;
    background-color: var(--primaryColor);
}

.HireTeamTable {
    width: 100%;
    overflow-x: scroll;
    padding-bottom: 50px;
    padding-right: 50px;
}

.HireTeamTable::-webkit-scrollbar {
    display: none;
}

.HireTeamTableContent {
    width: 3500px;
}

.HireTeamTableContentNew {
    width: 3500px;
}

.percentagetag {
    position: absolute;
    left: -2%;
    top: -46%;
    background-color: #1d37e7;
    border-radius: 20px;
    padding: 0px 8px;
    font-size: 12px;
    color: white;
    font-weight: 600;
    border: 1px solid white;
}

.HireTeamTableContent1 {
    width: 1500px;
}

.hireTableHead {
    border: 1px solid var(--dropBorderColor);
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
    background-color: var(--dropColor);
}

/* .tabletopborderRadius {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
}*/
.hireTableHead th {
    padding: 8px 20px;
    /* padding-right: 0px; */
    font-size: 16px;
    font-weight: 400;

    color: var(--textColor);
}

.hireTableBody {
    border-top: none;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: var(--white);
    border: 1px solid var(--dropBorderColor);
}

.hireTableBody h3 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    border-left: 1px solid var(--borderColor);
    padding: 0rem 0.5rem;
}

.hireTableBody h4 {
    /* display: flex; */
    height: 70px;
    border-left: 1px solid var(--borderColor);
    padding: 0rem 0.5rem;
    font-size: 12px;
    margin-top: 20px;
    width: 400px;
}

.hireTableBody td {
    padding: 8px 0px;
    text-align: center;
}

.hireTableBodydiv {
    /* padding: 10px 15px; */
    border-left: 1px solid var(--borderColor);
}

.hireTableBodyInput {
    width: 100%;
    border: none;
}

.hireTableBodyInput50 {
    width: 20%;
    border: none;
}

.text-blue-500:hover {
    text-decoration: underline;
    color: #0056b3;
    /* Darker shade of blue */
}

.successtag {
    color: green;
    font-weight: 500;
    font-size: 13px;
    margin-top: -10px;
}

.error_tag1 {
    color: red;
    font-weight: 500 !important;
    font-size: 13px !important;
    margin-top: -10px !important;
}

.hireTableBodySelect {
    border: 1px solid var(--borderColor);
    border-radius: 8px;
    padding: 3px;
    margin-left: 0px;
    width: 90%;
}

.hireTableButton {
    background-color: var(--primaryColor);
    font-size: 14px;
    color: var(--white);
    border-radius: 24px;
    padding: 1px 8px;
}

.hireTableButtonNew {
    background-color: var(--buttonDisableColor);
    font-size: 14px;
    color: var(--white);
    border-radius: 24px;
    padding: 1px 8px;
}

.hireTableButton1 {
    background-color: var(--primaryColor);
    font-size: 15px;
    color: var(--white);
    border-radius: 24px;
    padding: 5px 5px;
}

.h3Width {
    width: 200px;
}

.hireTeamCard {
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 7px 10px;
    border: 1px solid var(--borderColor);
    border-radius: 8px;
    background-color: var(--white);
}

.hireTeamCardActive {
    color: var(--white);
    background-color: var(--primaryColor);
}

.hireTeamCardFlex {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
}

.hireTeamCardContent {
    text-align: start;
}

.hireTeamCardContent1 {
    text-align: end;
}

.hireTeamCardContent h1 {
    font-size: 16px;
    font-weight: 600;
}

.hireTeamCardContent h2 {
    font-size: 12px;
    font-weight: 400;
    color: var(--textColor);
}

.hireTextColor {
    color: #D3D3D3 !important;
}

.hirebriefColor {
    color: #D3D3D3 !important;
}

.buttonColor {
    color: white;
    background-color: var(--primaryColor) !important;
}

.hireTeamCardContent h5 {
    font-size: 10px;
    font-weight: 500;
    color: var(--textColor);
    margin-left: 5px;
}

.hireTeamCardContent1 h6 {
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 15px;
}

.readMore {
    font-weight: 800;
    margin-left: 10px;
    font-size: 12px;
}

.xIcon {
    color: #EE9797;
    font-size: 20px;
    margin-left: 15px;
}

.plusIcon {
    color: #C4EE97;
    font-size: 20px;
    margin-left: 15px;
}

.HireTeamTableTop {
    padding: 10px 20px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    border: 1px solid var(--dropBorderColor);
    border-bottom: none;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.HireTeamTableToph1Active {
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 5px;
    color: var(--oldPrimaryColor);
    border-bottom: 2px solid var(--oldPrimaryColor);
}

.HireTeamTableToph1 {
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 5px;
    color: var(--textColorDark);
    border-bottom: 2px solid transparent;
}

.hireModuleCTA {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 50px;
}

.hireModuleCTA button {
    padding: 10px 50px;
    background-color: var(--primaryColor);
    color: var(--white);
    border-radius: 8px;
    margin-left: 20px;
}

.hireModuleCTA h1 {
    padding: 10px;
    background-color: var(--white);
    color: var(--textColorDark);
    border-radius: 8px;
    margin-left: 20px;
    border: 1px solid var(--borderColor);
}

.diversityContianer {
    width: 35%;
    background-color: var(--white);
    border-radius: 8px;
}

.diversityPopUpHead {
    border: 1px solid var(--dropBorderColor);
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
    background-color: var(--dropColor);
    padding: 12px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.diversityPopUpBody {
    padding: 15px 25px;
}

.diversitySelect {
    width: 100% !important;
    border: 1px solid var(--borderColor) !important;
    border-radius: 8px;
    padding: 8px;
    margin-bottom: 15px;
}

.diversityPopUpBody h2 {
    font-size: 16px;
    color: var(--textColor);
    margin-bottom: 20px;
    font-weight: 400;
}

.overlayBottomButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 25px;
    padding-right: 25px;
}

.Hiresave {
    padding: 4px 10px;
    border-radius: 8px;
    background-color: var(--primaryColor);
    color: var(--white);
    margin-left: 20px;
}

.HireDelete {
    padding: 4px 10px;
    border-radius: 8px;
    background-color: var(--rejectColor);
    color: var(--white);
    margin-left: 20px;
}

.Hirecancel {
    padding: 4px 10px;
    border-radius: 8px;
    border: 1px solid var(--borderColor);
    color: var(--textColorDark);
    margin-left: 20px;
}

.HireDot {
    padding: 10px 10px;
    border-radius: 8px;
    border: 1px solid var(--borderColor);
    color: var(--textColorDark);
    margin-left: 20px;
}

.readjobdescription {
    padding: 10px 15px;
    padding-bottom: 20px;
}

.hireTeamOverviewBack {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.backIcon1 {
    font-size: 18px;
    cursor: pointer;
    margin-right: 8px;
}

.hireBack {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.7;
    font-size: 14px;
}

.hireBack1 {
    display: flex;
    align-items: center;
    opacity: 0.7;
    font-size: 14px;
    cursor: pointer;
}

.hireTeamOverviewContent h1 {
    font-size: 28px;
    font-weight: 500;
    margin-top: 10px;
}

.hireTeamOverviewContent p {
    font-size: 14px;
    font-weight: 400;
    color: var(--textColor);
    margin-top: 5px;
}