.calendlyLink {
    width: 50%;
    position: relative;
}

.calendlyLink h4 {
    font-size: 14px;
    font-weight: 600;
}

.adminpersonalbutton {
    padding: 7px 20px;
    border-radius: 8px;
    color: var(--white);
    border: 1px solid var(--borderColor);
    background-color: var(--primaryColor);
    font-size: 15px;
    margin-bottom: 20px;
}

.adminpersonalbuttonnew {
    padding: 7px 20px;
    border-radius: 8px;
    color: var(--white);
    border: 1px solid var(--borderColor);
    background-color: var(--rejectColor);
    font-size: 15px;
    margin-bottom: 20px;
}


.calendlyLink h5 {
    font-size: 14px;
    font-weight: 600;
    color: var(--primaryColor);
    position: absolute;
    top: 57%;
    right: 3%;
    cursor: pointer;
}

.calendlyLink input {
    width: 100%;
    padding: 8px;
    border-radius: 8px;
    margin-top: 10px;
    border: 1px solid var(--borderColor);
}

.candidateRateSliderBody input {
    width: 100%;
    padding: 8px;
    border-radius: 8px;
    margin-top: 10px;
    border: 1px solid var(--borderColor);
}

.CandidateProfileViewCardBody {
    border: 1px solid var(--borderColor);
    border-bottom: none;
    border-radius: 8px;
}

.CandidateProfileViewCardBodyTable {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 8px;
    border-bottom: 1px solid var(--borderColor);
}

.CandidateProfileViewCardBodyTable h2 {
    width: 30%;
    font-size: 14px;
    font-weight: 600;
    padding: 15px 0px;
    margin-left: 10px;
}

.CandidateProfileViewCardBodyTable h3 {
    font-size: 14px;
    font-weight: 400;
    color: var(--textColor);
    padding: 15px 0px;
    width: 100%;
}

/* overlay */
.candidateRateCardOverlay {
    width: 55%;
    background-color: white;
    display: flex;
    z-index: 10 !important;
    flex-direction: column;
    /* padding: 10px; */
    border-radius: 12px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.candidateRateCardOverlayHead {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top-left-radius: 8px;
    border-top-Right-radius: 8px;
    background-color: var(--dropColor);
    border-bottom: 1px solid var(--dropBorderColor);
}

.candidateRateCardOverlayHead h1 {
    font-size: 16px;
    font-weight: 600;
}

.candidateRateCardOverlayTab {
    padding: 15px;
    padding-bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid var(--borderColor);
}



.candidateRateCardOverlayBody {
    padding: 15px 25px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px;
    row-gap: 20px;
}

.candidateRateSliderHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.candidateRateSliderHead h2 {
    font-size: 14px;
    font-weight: 600;
}

.candidateRateSliderHead h3 {
    font-size: 15px;
    font-weight: 400;
    opacity: 0.5;
}

.candidateRateSliderBody {
    width: 100%;
    text-align: center;

}


/* admin edit overlay */
.adminEditOverlay {
    width: 60%;
    height: 70%;
    overflow-y: scroll;
    background-color: white;
    display: flex;
    z-index: 10 !important;
    flex-direction: column;
    /* padding: 10px; */
    border-radius: 12px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.successmsg {
    font-size: 15px;
    color: green;
    font-weight: 600;
    margin-top: 5px;
}

.adminEditOverlay_interview {
    width: 40%;
    /* height: 50%; */
    /* overflow-y: scroll; */
    background-color: white;
    display: flex;
    z-index: 10 !important;
    flex-direction: column;
    /* padding: 10px; */
    border-radius: 12px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.adminEditOverlay1 {
    width: 30%;
    /* height: 60%; */
    /* overflow-y: scroll; */
    background-color: white;
    display: flex;
    z-index: 10 !important;
    flex-direction: column;
    /* padding: 10px; */
    border-radius: 12px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.adminEditOverlayInterview {
    width: 50%;
    height: 70%;
    background-color: white;
    display: flex;
    z-index: 10 !important;
    flex-direction: column;
    padding: 10px;
    border-radius: 12px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.adminEditOverlayInterview::-webkit-scrollbar {
    display: none;
}

.adminEditOverlay2 {
    width: 65%;
    /* height: 60%; */
    overflow-y: scroll;
    background-color: white;
    display: flex;
    z-index: 10 !important;
    flex-direction: column;
    /* padding: 10px; */
    border-radius: 12px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.contractCandidateDropdown {
    width: 45.5%;
    padding: 10px;
    border: 1px solid var(--borderColor);
    border-radius: 8px;
    background-color: var(--white);
    position: absolute;
    z-index: 10 !important;
}

.contractCandidateDropdown h5 {
    font-size: 15px;
    font-weight: 500;
    padding: 5px 10px;
    margin-bottom: 5px;
    border-radius: 8px;
}

.contractCandidateDropdown h5:hover {
    background-color: var(--borderColor);
}

.adminEditOverlayHead {
    padding: 15px 20px;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: var(--dropColor);
    border-bottom: 1px solid var(--borderColor);
}

.adminEditOverlayBody {
    padding: 0px 20px 20px 20px;
    /* display: grid;
        grid-template-columns: repeat(2, 1fr); */
    column-gap: 25px;
    height: 77%;
    overflow-y: scroll;
}

.adminEditOverlayBodyWarp {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 25px;
    border: 1px solid var(--borderColor);
    border-radius: 8px;
    padding: 0px 15px;
    padding-bottom: 15px;
    margin-top: 20px;
    margin-bottom: 20px;

}

.adminEditOverlayBody h2 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 15px;
}

.adminEditOverlayBody input {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid var(--borderColor);
}

.videopreview {
    width: 100%;
    padding: 20px;
    border: 1px solid var(--borderColor);
    border-radius: 8px;
    margin: 20px 0px;
    position: relative;
}

.adminEditOverlayBody textarea {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid var(--borderColor);
}

.adminEditOverlayBody select {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid var(--borderColor);
}

.editOverlayButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px 20px 20px 20px;

}

.adminEditAddMore {
    width: 95.5%;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid var(--borderColor);
    margin-top: 5px;
    margin-bottom: 25px;
}

/* upload  */
.adminEditOverlayContent h3 {
    font-size: 14px;
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 10px;
}

.adminEditOverlayUpload {
    width: 100%;
    padding: 20px;
    border-radius: 8px;
    border: 1px dotted var(--borderColor);
    cursor: pointer;
}

.adminEditOverlayUploadHead {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.adminEditOverlayUploadHead img {
    height: 32px;
    padding: 5px;
    background-color: var(--white);
    border: 1px solid var(--borderColor);
    border-radius: 5px;
    margin-right: 15px;
}

.adminEditOverlayUploadHead h4 {
    font-size: 14px;
    font-weight: 500;
    opacity: 0.6;
}

.adminEditOverlayUploadHead h5 {
    font-size: 12px;
    font-weight: 600;
    opacity: 0.6;
}

.adminEditOverlayUpload p {
    font-size: 12px;
    font-weight: 400;
    color: var(--textColor);
    margin-top: 10px;
    margin-bottom: 10px;
}

.adminEditOverlayUpload button {
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    padding: 6px;
    border-radius: 8px;
    border: 1px solid var(--borderColor);
    background-color: var(--white);
}

/* approve Candidate */
.approveCandidateOverlay {
    width: 35%;
    background-color: white;
    display: flex;
    z-index: 10 !important;
    flex-direction: column;
    /* padding: 10px; */
    border-radius: 12px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.approveCandidateOverlayPaypal {
    width: 40%;
    background-color: white;
    display: flex;
    z-index: 10 !important;
    flex-direction: column;
    /* padding: 10px; */
    border-radius: 12px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 50%;
    overflow-y: scroll;
}

.approveCandidateOverlayFirst {
    width: 40%;
    background-color: white;
    display: flex;
    z-index: 10 !important;
    flex-direction: column;
    /* padding: 10px; */
    border-radius: 12px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height:auto;
}

.approveCandidateOverlayPaypal::-webkit-scrollbar {
    display: none;
}

.approveCandidateOverlayBody p {
    font-size: 14px;
    font-weight: 400;
    padding: 20px;
    color: var(--textColor);
}

.deleteIcon {
    /* position: absolute; */
    right: 3%;
    top: 8%;
    color: #EF4444;
    font-size: 18px;
    z-index: 1;
}

.approveCandidateOverlayBody h2 {
    font-size: 15px;
    font-weight: 500;
    padding: 0px 20px;
    color: var(--textColor);
    margin-bottom: 10px;
    margin-top: 10px;
    color: #EF4444;
    text-align: center;
}

#deletebtn {
    padding: 10px 15px;
    background-color: var(--rejectColor) !important;
    color: var(--white);
    font-size: 14px;
    display: flex;
    border: none;
    border-radius: 8px;
    align-items: center;
}

.approveCandidateOverlayButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px;

}

@media only screen and (max-width: 600px) {
    .calendlyLink {
        width: 100%;
        padding-top: 15px;
    }

    .calendlyLink h5 {
        top: 64%;
    }

    .calendlyLink input {
        padding: 6px;
        padding-right: 45px;
    }

    /* overlay */
    .adminEditOverlay {
        width: 100%;
        height: 100%;

    }

    .adminEditOverlay1 {
        width: 100%;
        height: 100%;

    }

    .adminEditOverlay2 {
        width: 100%;
        /* height: 100%; */

    }

    .adminEditOverlayBody {
        /* height: 100%; */
        grid-template-columns: repeat(1, 1fr);
        overflow-y: scroll;
    }

    /* .adminEditOverlayContent {
            height: 40px;
        } */

    .editOverlayButton {
        padding: 20px;
        flex-direction: column;

    }

    .adminEditAddMore {
        width: 88.5%;
        margin-bottom: 0px;
    }

    .approveCandidateOverlay {
        width: 90%;
    }

    .approveCandidateOverlayButton {
        flex-direction: column
    }

    .adminEditOverlayBodyWarp {
        grid-template-columns: repeat(1, 1fr);
    }

    /* rate card */
    .candidateRateCardOverlay {
        width: 100%;
    }

    .CandidateProfileViewCardBodyTable h2 {
        width: 50%;
    }

    .CandidateProfileViewCardBodyTable h3 {
        width: 50%;
        overflow: scroll;
    }
}